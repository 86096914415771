<template>
   <footer class="iq-footer">
        <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-6">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item"><a href="@/backend/privacy-policy.html">Privacy Policy</a></li>
                            <li class="list-inline-item"><a href="@/backend/terms-of-service.html">Terms of Use</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-6 text-right">
                        <span class="mr-1">2021©</span> <a href="#" class="">{{ appName }}</a>.
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name:"Footer",
    prop:{
    },
    computed : {
        ...mapGetters({
            appName: 'appName'
        })
    }
}
</script>